class Lightbox {
  constructor(container) {
    this.container = container
    this.lightbox = document.querySelector('.lightbox')
    this.close = this.lightbox.querySelector('.icon--close')
    this.video = this.lightbox.querySelector('video')

    this.init();
  }

  init() {
    this.container.addEventListener('click', (e) => {
      e.preventDefault();
      this.openLightbox()
    })
  }

  openLightbox() {
    this.lightbox.classList.add('active')
    this.video.play();
    this.close.addEventListener('click', () => {
      this.closeLightbox()
    })
  }

  closeLightbox() {
    this.video.pause();
    this.lightbox.classList.remove('active')
  }
}

document.querySelectorAll('.js-lightbox').forEach((container) => {
  new Lightbox(container)
})
