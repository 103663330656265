import DDKeyFigures from "dd-keyfigures";
import merge from "lodash.merge";
import de from "./locale/de.json";
import en from "./locale/en.json";
import I18N from "./i18n";
import globalOptions from "./global_options";

const i18n = new I18N({ ...de, ...en });

const styles = {
  colorGreen: "#00569D",
  colorYellow: "#5CA2CE",
  colorGrey: "#b5b5b5",
  colorOrange: "#EB5B25",
  colorLightblue: "#8EC8D2",
  colorLightgrey: "#1D3E68",
  colorDark: "#000",
};

const settings = {
  mode: "accordion",
  locale: i18n.getLocaleID(),
  features: {
    lineChart: {
      enabled: false,
    },
    indexToggle: {
      enabled: false,
    },
    legendSwitch: {
      enabled: false,
    },
  },
  highlightRow: 5,
};

const profit = {
  labels: ["2018", "2019", "2020", "2021", "2022"],
  datasets: [
    {
      label: i18n.t("profit.labels.auftragseingang"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      // yAxisID: "y2",
      order: 1,
      data: [2303.5, 2453.8, 2143.4, 2411.7, 2862.1],
      backgroundColor: styles["colorYellow"],
      borderColor: styles["colorYellow"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.umsatz"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      data: [2245.9, 2383.2, 2207.9, 2343.6, 2573.4],
      order: 1,
      backgroundColor: styles["colorGreen"],
      borderColor: styles["colorGreen"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.auftragsbestand"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      // yAxisID: "y2",
      // type: "line",
      order: 1,
      data: [1353.9, 1409.3, 1288.5, 1366.2, 1497.8],
      backgroundColor: styles["colorLightblue"],
      borderColor: styles["colorLightblue"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("profit.labels.ebit"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      // yAxisID: "y2",
      data: [74.7, 113.6, 70.2, 141.2, 169.1],
      backgroundColor: styles["colorOrange"],
      borderColor: styles["colorOrange"],
      minimumFractionDigits: 1,
    },
  ],
};

const ergebnis = {
  labels: ["2018", "2019", "2020", "2021", "2022"],
  datasets: [
    {
      label: i18n.t("ergebnis.labels.ebitda"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      // yAxisID: "y2",
      data: [179.2, 195.5, 170.1, 222.1, 259.5],
      backgroundColor: styles["colorDark"],
      borderColor: styles["colorDark"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("ergebnis.labels.ebit"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      // yAxisID: "y2",
      data: [74.7, 113.6, 70.2, 141.2, 169.1],
      backgroundColor: styles["colorOrange"],
      borderColor: styles["colorOrange"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("ergebnis.labels.ebt"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      // yAxisID: "y2",
      data: [65.6, 103.4, 61.6, 139.9, 160.7],
      backgroundColor: styles["colorGreen"],
      borderColor: styles["colorGreen"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("ergebnis.labels.ertragssteuern"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      // yAxisID: "y2",
      data: [23.9, 58.5, 4.4, 110.3, 127.3],
      backgroundColor: styles["colorLightblue"],
      borderColor: styles["colorLightblue"],
      minimumFractionDigits: 1,
    },
    {
      label: i18n.t("ergebnis.labels.cashflow"),
      unit: {
        chart: i18n.t("units.mio_euro_value"),
        table: i18n.t("units.mio_euro"),
      },
      type: "line",
      order: 0,
      // yAxisID: "y2",
      data: [-29.1, 70.9, 111.6, 87.5, -86.9],
      backgroundColor: styles["colorGrey"],
      borderColor: styles["colorGrey"],
      minimumFractionDigits: 1,
    },
  ],
};

const profitOptions = {
  scales: {
    y: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
    },
    // y2: {
    //   title: {
    //     text: i18n.t("units.mio_euro"),
    //   },
    // },
  },
  footnotes: [i18n.t("profit.footnotes.1")],
};

const ergebnisOptions = {
  scales: {
    y: {
      title: {
        text: i18n.t("units.mio_euro"),
      },
    },
    // y2: {
    //   title: {
    //     text: i18n.t("units.mio_euro"),
    //   },
    // },
  },
  footnotes: [i18n.t("ergebnis.footnotes.1"), i18n.t("ergebnis.footnotes.2"), i18n.t("ergebnis.footnotes.3"), i18n.t("ergebnis.footnotes.4")],
};

const config = {
  profit: {
    type: "bar",
    title: i18n.t("profit.title"),
    data: profit,
    options: merge({}, globalOptions, profitOptions),
  },
  ergebnis: {
    type: "bar",
    title: i18n.t("ergebnis.title"),
    data: ergebnis,
    options: merge({}, globalOptions, ergebnisOptions),
  },
};

if (document.querySelector('#dd-keyfigures')) {
  const keyfigures = new DDKeyFigures(config, settings);
  keyfigures.init();
}
