import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export class Slider {
  constructor(slider) {
    this.slider = slider
    this.pagination = slider.querySelector('.swiper-pagination')
    // this.prevButton = slider.querySelector('.swiper-button-prev')
    // this.nextButton = slider.querySelector('.swiper-button-next')
    this.init()
  }

  init() {
    const swiper = new Swiper(this.slider, {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      autoHeight: true,
      loop: true,
      pagination: {
        el: this.pagination,
      },
      // navigation: {
      //   nextEl: this.nextButton,
      //   prevEl: this.prevButton,
      // },
      autoplay: {
        delay: 5000,
      },
    });
  }

}

// init
document.querySelectorAll('.js-slider').forEach((slider) => {
  new Slider(slider)
})
