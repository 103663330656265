var ScrollMagic = require('scrollmagic');
var scrollController = new ScrollMagic.Controller();


class scrollReveal {
  constructor(container) {
    this.self = container
    this.init()
  }

  init() {
    var options_scroll = {
      triggerElement: this.self,
      // triggerHook: .6,
      triggerHook: 'onEnter',
      offset: 50
    }

    new ScrollMagic.Scene(options_scroll)
    .setClassToggle(this.self, 'is-active')
    .addTo(scrollController);
  }

}

class scrollRevealImage {
  constructor(container) {
    this.self = container
    this.init()
  }

  init() {
  var options_scroll = {
    triggerElement: this.self,
    // triggerHook: .6,
    triggerHook: 'onEnter',
    offset: 50
  }

  new ScrollMagic.Scene(options_scroll)
  .setClassToggle(this.self, 'is-active')
  .addTo(scrollController);
  }

}

document.querySelectorAll('.reveal').forEach((container) => {
  new scrollReveal(container)
})
document.querySelectorAll('.reveal--image').forEach((container) => {
  new scrollRevealImage(container)
})
