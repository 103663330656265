import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

class LineGraphic {
  constructor(container) {
    this.container = container
    this.svg = this.container.querySelector('svg');
    this.items = this.svg.querySelectorAll('#items > g');
    this.lines = this.svg.querySelectorAll('#lines line');
    this.arrows = this.svg.querySelectorAll('#lines polygon');

    this.init()
  }

  init() {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger:this.container,
        scrub: true,
        start: "10% center",
        end: "80% center",
      }
    })

    tl.from(this.lines, {
      stagger: 0.25,
      drawSVG: 0,
    });
    tl.from(this.arrows, {
      stagger: 0.25,
      autoAlpha: 0,
    }, "-=0.25");


    tl.from(this.items, {
      autoAlpha: 0,
      stagger: 0.25,
    }, "0")
  }
}



document.querySelectorAll('.js-line-graphic').forEach((container) => {
  new LineGraphic(container)
})
