import de from "./locale/de.json";
import en from "./locale/en.json";
import I18N from "./i18n";

const i18n = new I18N({ ...de, ...en });

const globalOptions = {
  locale: i18n.getLocaleID(),
  maintainAspectRatio: false,
  animation: true,
  maxBarThickness: 24,
  font: {
    family: "Frutiger-Reg",
  },
  interaction: {
    mode: "dataset",
  },
  plugins: {
    legend: {
      display: false,
      title: {
        font: {
          size: 16,
          family: "Frutiger-Reg",
          weight: 400,
        },
      },
      labels: {
        textAlign: "left",
        padding: 20,
        boxWidth: 16,
        boxHeight: 16,
        font: {
          size: 16,
          weight: 400,
          family: "Frutiger-Reg",
        },
      },
    },
    tooltip: {
      titleFont: {
        size: 12,
        weight: 500,
      },
      bodyFont: {
        size: 14,
        weight: 400,
        family: "Frutiger-Reg",
      },
      mode: "index",
      labels: {
        boxWidth: 16,
        boxHeight: 16,
      },
      backgroundColor: "#F2F6F8",
      titleColor: "#000",
      bodyColor: "#ßßß",
      bodySpacing: 6,
      titleMarginBottom: 10,
      padding: 15,
    },
    datalabels: {
      backgroundColor: "#F2F6F8",
      color: "#000",
      font: {
        size: 14,
        weight: 400,
        family: "Frutiger-Reg",
      },
      borderRadius: 5,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawTicks: false,
        lineWidth: 0,
      },
      ticks: {
        font: {
          weight: 400,
          family: "Frutiger-Reg",
          fontColor: "#666",
        },
      },
    },
    y: {
      type: "linear",
      position: "left",
      title: {
        display: true,
        font: {
          weight: 400,
          family: "Frutiger-Reg",
          fontColor: "#000",
        },
      },
      grid: {
        drawBorder: false,
        borderColor: "#b2b1a9",
        color: "#b2b1a9",
      },
      ticks: {
        maxTicksLimit: 6,
        font: {
          weight: 400,
          family: "Frutiger-Reg",
          fontColor: "#000",
        },
      },
    },
    y2: {
      type: "linear",
      position: "right",
      display: "auto",
      title: {
        display: true,
        font: {
          weight: 400,
          family: "Frutiger-Reg",
          fontColor: "#000",
        },
      },
      grid: {
        drawBorder: false,
        borderColor: "#F2F6F8",
        color: "#F2F6F8",
      },
      ticks: {
        maxTicksLimit: 6,
        font: {
          weight: 400,
          family: "Frutiger-Reg",
          fontColor: "#000",
        },
      },
    },
  },
};

export default globalOptions;
