import 'regenerator-runtime/runtime'

import "/_atoms/countup/countup.ts";
// import "/_atoms/toTop/toTop.ts";


// import "/_components/accordion/accordion.ts";
import "/_components/scroll-reveal/scroll-reveal.ts";
import "/_components/dd-keyfigures/keyfigures.js";
import "/_components/dd-keyfigures/i18n.js";
import "/_components/slider/slider.ts";
import "/_components/navigation/navigation.ts";
import "/_components/interactive-graphic/interactive-graphic.ts";
import "/_components/animations/stagger.ts";
import "/_components/animations/line-graphic.ts";
import "/_components/animations/morph.ts";
import "/_components/lightbox/lightbox.ts";

