var $ = require('jquery');
var level1_trigger = $('.nav__toggle');
var level1 = $('.nav__level1');
// var level2_trigger = $('.js-button--home');
var level2_trigger = $('.js-button--mag');
var level1_ul = $('.nav-ul');
var level2 = $('.nav__level2');
var level2_close = $('.js-button--close')

level2_trigger.on('click', function () {
  $(this).toggleClass('open')
})

level2_close.on('click', function () {
  level2_trigger.classList.remove('open')
})

$('.mobile-trigger').on('click', function () {
  $('.nav__level1__content').toggleClass('is-active');
})
