import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

class MorphGraphic {
  constructor(container) {
    this.container = container
    this.svg = this.container.querySelector('svg');
    this.allBase = this.svg.querySelectorAll('#base > *');
    this.allMorphed = this.svg.querySelectorAll('#morphed > *');

    this.init()
  }

  init() {
    gsap.set(this.svg, {
      maxWidth: "100%"
    })
    gsap.set(this.allMorphed, {
      autoAlpha: 0
    })

    this.allBase.forEach((base, key) => {
      key++
      const morphed = this.svg.querySelector(`#morphed-${key}`);

      gsap.to(base, {
        morphSVG: {
          shape: morphed,
          // type: "rotational",
          // origin: "20% 50%"
          // precision: 5
        },
        duration: 4,
        ease: "none"
        repeat: -1,
        repeatDelay: 2,
        yoyo: true,
      });

    })

  }
}



document.querySelectorAll('.js-morph').forEach((container) => {
  new MorphGraphic(container)
})
