import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

class InteractiveGraphic {
  constructor(container) {
    this.container = container
    this.svg = this.container.querySelector('svg');
    this.pulses = this.svg.querySelectorAll('#pulse > g');
    this.parts = this.svg.querySelectorAll("[id^='part-']");
    this.infoboxesContainer = this.container.querySelector('.interactive-graphic__infoboxes');
    this.infoboxes = this.container.querySelectorAll('.interactive-graphic__infobox');
    this.inside = this.container.querySelectorAll('.interactive-graphic__infobox, #pulse > g');

    this.init()
  }

  init() {

    this.pulses.forEach((item) => {
      item.addEventListener("click", () => {
        this.openInfobox(item)
        this.highlight(item)
      })
    })

    this.onClickOutside(this.infoboxesContainer)
    this.pulseAnim()

  }

  pulseAnim() {
    this.pulses.forEach((pulse) => {
      const circle = pulse.querySelector('ellipse')
      const circleClone = circle.cloneNode();
      pulse.append(circleClone)

      gsap.set(circle, {
        fill: "rgba(179, 204, 226, .6)",
      })

      gsap.to(circle, {
        transformOrigin: "center",
        fill: "rgba(179, 204, 226, 0)",
        scale: 1.8,
        duration: 1.5,
        ease: "none",
        repeat: -1,
        repeatDelay: 0.5,
      })
    })
  }

  openInfobox(pulse) {
    this.closeInfoboxes()

    const no = pulse.id
    const infobox = this.container.querySelector(`.interactive-graphic__infobox[data-no="${no}"]`)

    gsap.to(infobox, {
      autoAlpha: 1,
    })

  }

  highlight(item) {
    const no = item.id
    const part = this.container.querySelector(`#part-${no}`)
    gsap.to(part, {
      filter: "drop-shadow(0px 50px 20px rgba(0, 0, 0, 0.5))"
    })
  }

  closeInfoboxes() {
    this.infoboxes.forEach((item) => {
      gsap.to(item, {
        autoAlpha: 0
      })
    })

    this.parts.forEach((part) => {
      gsap.to(part, {
        filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.5))"
      })
    })
  }

  onClickOutside(ele) {
    document.addEventListener('click', event => {
      if (!ele.contains(event.target) && event.target.parentElement.parentElement.id != "pulse" ) {
        this.closeInfoboxes();
      };
    });
  };

}

document.querySelectorAll('.js-interactive-graphic').forEach((container) => {
  new InteractiveGraphic(container)
})
