import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

class Stagger {
  constructor(container) {
    this.container = container
    this.items = this.container.querySelectorAll('#stagger > *')

    this.init()

  }

getDelay() {
  if (!this.container.dataset.delay) {
    return 0.5
  }

  return this.container.dataset.delay
}

getDuration() {
  if (!this.container.dataset.duration) {
    return 1
  }

  return this.container.dataset.duration
}

init() {
  gsap.set(this.items, {
    autoAlpha: 0,
  })
    gsap.to(this.items, {
      scrollTrigger: this.container,
      duration: this.getDuration(),
      autoAlpha: 1,
      stagger: this.getDelay(),
    })
}

}

class ScrollReveal {
  constructor(container) {
    this.container = container
    this.items = this.container.querySelectorAll('#stagger > *')

    this.init()

  }


init() {
  gsap.set(this.items, {
    autoAlpha: 0,
  })

  // use this instead of the above if items should be revealed by scrollTrigger
  this.items.forEach((item) => {
    gsap.to(item, {
      autoAlpha: 1,
      stagger: 0.5,
      scrollTrigger: {
        trigger: item,
        start: "top center",
        // markers:true,
      }
    })
  })

}

}

document.querySelectorAll('.js-stagger-reveal').forEach((container) => {
  new Stagger(container)
})

document.querySelectorAll('.js-scroll-reveal').forEach((container) => {
  new ScrollReveal(container)
})
