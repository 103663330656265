export default class I18N {
  constructor(locales) {
    this.lang = document.querySelector("html").getAttribute("lang");
    this.translations = { ...locales };
  }

  t(key) {
    let translations = this.translations[this.lang];
    let value = key.split(".").reduce(function (p, prop) {
      return p[prop];
    }, translations);
    return value;
  }

  getLocaleID() {
    return this.translations[this.lang].id;
  }

  getLang() {
    return this.lang;
  }
}
